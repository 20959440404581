import React, { useState } from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import PortfolioCard from '@components/PortfolioCard';
import Hero from '@components/Hero';
import SEO from '@components/Seo';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import CustomSwiper from '@components/CustomSwiper';
import Link from 'gatsby-link';

const ServiceCategory = ({
    data: {
        page: {
            frontmatter: {
                name,
                description,
                image,
                overviewTextTitle,
                overviewTextDescription,
                overviewListTitle,
                overviewListItems,
                swiperImages,
                timelineDescription,
                ctaTitle,
                ctaButtonText,
                ctaButtonLink,
                timelineItems,
            },
        },
        portfolio: { edges: portfolio },
        servicesCategories: { edges: servicesCategories },
    },
}) => {
    const categoryIndex = servicesCategories?.findIndex(
        ({
            node: {
                frontmatter: { heading },
            },
        }) => heading === name
    );
    const previousCategory =
        categoryIndex === 0
            ? servicesCategories[servicesCategories.length - 1]
            : servicesCategories[categoryIndex - 1];
    const nextCategory =
        categoryIndex === servicesCategories.length - 1
            ? servicesCategories[0]
            : servicesCategories[categoryIndex + 1];

    const previousCategoryHeading = previousCategory?.node?.frontmatter?.heading;
    const previousCategoryLink = previousCategory?.node?.frontmatter?.path;
    const nextCategoryHeading = nextCategory?.node?.frontmatter?.heading;
    const nextCategoryLink = nextCategory?.node?.frontmatter?.path;

    return (
        <Layout>
            <SEO title={`${name} • Services`} />
            <Hero
                title={name}
                description={description}
                previousCategoryHeading={previousCategoryHeading}
                previousCategoryLink={previousCategoryLink}
                nextCategoryHeading={nextCategoryHeading}
                nextCategoryLink={nextCategoryLink}
            />

            <div className={styles.serviceWrapper}>
                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />
                <Grid>
                    <section className={styles.servicesOverviewWrapper}>
                        <Row>
                            <Col xs={12} md={7}>
                                <h2>{overviewTextTitle}</h2>
                                <div
                                    className={styles.overviewText}
                                    dangerouslySetInnerHTML={{ __html: overviewTextDescription }}
                                />
                            </Col>
                            <Col xs={12} md={5}>
                                <Divider margin={5} />
                                <h2>{overviewListTitle}</h2>
                                <ul>
                                    {overviewListItems?.map((item) => (
                                        <li key={item}>
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </section>
                </Grid>

                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <KnowMoreCta
                    text={<h2>{ctaTitle}</h2>}
                    buttonText={ctaButtonText}
                    link={ctaButtonLink}
                />

                <section>
                    <CustomSwiper>
                        {swiperImages?.map(({ childImageSharp: { fluid: image } }, index) => (
                            <SwiperSlide key={`${image?.src}-${index}`}>
                                <div className={styles.swiperImageContainer}>
                                    <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                </section>

                <section>
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.timelineHeading}>
                                    <h2>The Process</h2>
                                    <p>{timelineDescription}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.timelineWrapper}>
                                    <VerticalTimeline>
                                        {timelineItems?.map((item, index) => (
                                            <VerticalTimelineElement
                                                className="vertical-timeline-element--work"
                                                iconStyle={{ display: 'none' }}
                                                key={`${item?.title}-${index}`}
                                                contentArrowStyle={{ display: 'none' }}
                                            >
                                                <h3>{item?.title}</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.description,
                                                    }}
                                                />
                                            </VerticalTimelineElement>
                                        ))}
                                    </VerticalTimeline>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className={styles.portfolioWrapper}>
                    <Divider margin={5} />
                    <Divider xsMargin={0} smMargin={5} />

                    <Grid>
                        <div className={styles.portfolioCardContainer}>
                            <Row>
                                {portfolio?.map((data) => (
                                    <Col xs={12} key={data?.node?.frontmatter?.heading}>
                                        <div className={styles.portfolioCardWrapper}>
                                            <PortfolioCard data={data} />
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Grid>

                    <Divider margin={5} />
                    <Divider xsMargin={0} smMargin={5} />
                </section>
            </div>
        </Layout>
    );
};

export default ServiceCategory;

export const query = graphql`
    query($path: String, $code: String, $serviceCode: String) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                name: services_service_heading
                description: services_service_description
                image: services_service_image {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                overviewTextTitle: services_service_overview_text_title
                overviewTextDescription: services_service_overview_text_description
                overviewListTitle: services_service_overview_list_title
                overviewListItems: services_service_overview_list_items
                ctaTitle: services_service_cta_title
                ctaButtonText: services_service_cta_button_text
                ctaButtonLink: services_service_cta_button_link
                swiperImages: services_service_swiper_images {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                timelineDescription: services_service_timeline_heading
                timelineItems: services_service_timeline_items {
                    title: services_service_timeline_items_title
                    description: services_service_timeline_items_description
                }
            }
        }
        servicesCategories: allMarkdownRemark(
            filter: {
                frontmatter: { services_service_items: { in: [$serviceCode] } }
                fileAbsolutePath: { regex: "/servicesCategories/" }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        heading: services_service_heading
                        description: services_service_card_description
                    }
                }
            }
        }
        portfolio: allMarkdownRemark(
            filter: {
                frontmatter: { portfolio_item_services_categories: { in: [$code] } }
                fileAbsolutePath: { regex: "/case-studies/" }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        heading: portfolio_item_title
                        subHeading: portfolio_item_sub_heading
                        desktopImage: portfolio_item_desktop_image {
                            childImageSharp {
                                fluid(maxWidth: 550, maxHeight: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        bgImage: portfolio_item_bg {
                            childImageSharp {
                                fluid(maxWidth: 650, maxHeight: 350) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        description: portfolio_item_description
                        date: portfolio_item_date
                        read_time: portfolio_item_readtime
                        services: portfolio_item_services
                        servicesCategories: portfolio_item_services_categories
                        favourite: portfolio_item_favourite
                        displayedServices: portfolio_item_displayed_services {
                            path: portfolio_item_displayed_services_link
                            name: portfolio_item_displayed_services_name
                        }
                    }
                }
            }
        }
    }
`;
