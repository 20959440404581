import React from 'react';
import styles from './styles.module.scss';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Button from '@components/Button';
import { Link } from 'gatsby';

const KnowMoreCta = ({ text, link = '/#', buttonText }) => {
    return (
        <div className={styles.knowMoreCtaWrapper}>
            <Grid>
                <Col xs={12}>
                    <div className={styles.knowMoreCta}>
                        {text}
                        {link.startsWith('mailto') ? (
                            <a href={link}>
                                <Button text={buttonText} />
                            </a>
                        ) : (
                            <Link to={link}>
                                <Button text={buttonText} link={link} />
                            </Link>
                        )}
                    </div>
                </Col>
            </Grid>
        </div>
    );
};

export default KnowMoreCta;
